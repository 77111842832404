#app {
  margin-top: 1rem;
}

.div {
  box-sizing: border-box;
}

.logo-container {
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.scheme-container {
  display: none;
}